@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@100;300;400;900&display=swap');
//@import url('https://unpkg.com/@tailwindcss/typography@0.2.x/dist/typography.min.css');
//@import url('https://unpkg.com/tailwindcss@^2/dist/tailwind.min.css');
//@import url('https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css');
//@import 'bootstrap.css';
@import 'tailwindcss/dist/tailwind.min.css';
@import 'bootstrap/scss/bootstrap.scss';
@import 'bootstrap/scss/_nav.scss';
@import 'bootstrap/scss/_navbar.scss';

:root {
  --base-unit: 17;

  /* Font sizes */
  --font-micro-size: calc(8 / var(--base-unit) * 1rem);
  --font-micro-line-height: 1;
  --font-small-size: calc(14 / var(--base-unit) * 1rem);
  --font-small-line-height: calc(18 / 14);
  --font-base-size: calc(var(--base-unit) / 16 * 100%);
  --font-base-line-height: calc(22 / var(--base-unit));
  --font-large-size: calc(19 / var(--base-unit) * 1rem);
  --font-large-line-height: calc(24 / 19);
  --font-title3-size: calc(24 / var(--base-unit) * 1rem);
  --font-title3-line-height: calc(28 / 24);
  --font-title2-size: calc(32 / var(--base-unit) * 1rem);
  --font-title2-line-height: calc(36 / 32);
  --font-title1-size: calc(44 / var(--base-unit) * 1rem);
  --font-title1-line-height: calc(56 / 44);
}

.title1 {
  font-size: var(--font-title1-size);
  line-height: var(--font-title1-line-height);
}

.title2 {
  font-size: var(--font-title2-size);
  line-height: var(--font-title2-line-height);
}

.title3 {
  font-size: var(--font-title3-size);
  line-height: var(--font-title3-line-height);
}

.large {
  font-size: var(--font-large-size);
  line-height: var(--font-large-line-height);
}

.base {
  font-size: inherit;
  line-height: inherit;
}

.small {
  font-size: var(--font-small-size);
  line-height: var(--font-small-line-height);
}

.micro {
  font-size: var(--font-micro-size);
  line-height: var(--font-micro-line-height);
  text-transform: uppercase;
}

/*
 * Responsively sized elements
 */

.paragraph {
  font-size: var(--font-base-size);
  line-height: var(--font-base-line-height);
  margin: 0.5rem 0 1rem 0;

  @media (--media-min-small) {
    font-size: var(--font-base-size);
    line-height: var(--font-base-line-height);
  }

  @media (--media-min-medium) {
    font-size: var(--font-large-size);
    line-height: var(--font-large-line-height);
  }
}

.blockQuote {
  background: #eee;
}

.responsiveTitle1 {
  font-weight: 900;
  font-size: var(--font-title3-size);
  line-height: var(--font-title3-line-height);
  margin: 1rem 0 2rem;

  @media (--media-min-small) {
    font-size: var(--font-title2-size);
    line-height: var(--font-title2-line-height);
  }

  @media (--media-min-medium) {
    font-size: var(--font-title1-size);
    line-height: var(--font-title1-line-height);
  }
}

.responsiveTitle2 {
  font-weight: 900;
  font-size: var(--font-large-size);
  line-height: var(--font-large-line-height);
  margin: 1.5rem 0 0.5rem;

  @media (--media-min-small) {
    font-size: var(--font-title3-size);
    line-height: var(--font-title3-line-height);
  }

  @media (--media-min-medium) {
    font-size: var(--font-title2-size);
    line-height: var(--font-title2-line-height);
  }
}

.responsiveTitle3 {
  font-weight: 900;
  font-size: var(--font-large-size);
  line-height: var(--font-large-line-height);
  margin: 1rem 0 0.5rem;

  @media (--media-min-small) {
    font-size: var(--font-large-size);
    line-height: var(--font-large-line-height);
  }

  @media (--media-min-medium) {
    font-size: var(--font-title3-size);
    line-height: var(--font-title3-line-height);
  }
}

.responsiveTitle4 {
  font-size: var(--font-base-size);
  line-height: var(--font-base-line-height);
  margin: 1rem 0 0.5rem;

  @media (--media-min-small) {
    font-size: var(--font-base-size);
    line-height: var(--font-base-line-height);
  }

  @media (--media-min-medium) {
    font-size: var(--font-large-size);
    line-height: var(--font-large-line-height);
  }
}

html,
body {
  height: 100%;
  //background-color: #e4ffe8 !important;
}

body {
  //display: -ms-flexbox;
  display: -webkit-box-flex;
  //display: flex;
  //-ms-flex-align: top;
  //-ms-flex-pack: top;
  //-webkit-box-align: top;
  //align-items: top;
  //-webkit-box-pack: top;
  //justify-content: left;
  //padding-top: 60px;
  //padding-left: 20px;
  //padding-bottom: 40px;
  //background-color: #fef8ed !important;
  background-color: #fffbf2 !important;
  //background-color: #82e2e9;
}

h1 {
  font-family: 'Roboto Condensed';
  font-weight: 800;
  font-size: 21px;
}
h2 {
  font-family: 'Roboto Condensed';
  font-weight: 800;
  font-size: 18px;
}
h3 {
  font-family: 'Roboto Condensed';
  font-weight: 800;
  font-size: 16px;
}
h4 {
  font-family: 'Roboto Condensed';
  font-weight: 800;
  font-size: 14px;
}
p {
  font-family: 'Roboto Condensed';
  font-size: 14px;
}

.footer {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  text-align: center;
  font-size: var(--font-small-size);
  line-height: var(--font-small-line-height);
  //font-size: 0.85rem;
  position: fixed;
  font-weight: 200;
  //left: 0px;
  bottom: 33px;
  height: 30px;
  width: 100%;
  //background: #999;
}
.footer-relative {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  text-align: center;
  font-size: var(--font-small-size);
  line-height: var(--font-small-line-height);
  //font-size: 0.85rem;
  //position: fixed;
  font-weight: 200;
  //left: 0px;
  bottom: 33px;
  height: 30px;
  width: 100%;
  //background: #999;
}

.imggrid {
  padding-left: 60px;
}

.post-image {
  padding-bottom: 170px;
  padding-right: 140px;
  object-fit: cover;
  transition: all .6s ease 0s;
}
.post-image:hover {
  border: 2px solid #04ADBF;
  border-radius: 5px;
  transition: all 0.6s ease 0s;
  // let clr1 = p.color('#04ADBF'); // teal
  // let clr2 = p.color('#025959'); // greenish
  // let clr3 = p.color('#F2E0C9'); // tan brown
  // let clr4 = p.color('#A0A603'); // yellow green
  // let clr5 = p.color('#04BFBF'); // teal
  
}
.social {
  transition: all 0.8s ease 0s;
  
  > a > img {
  color: rgba(0,0,0,.2);
  opacity: .55 !important;
  transition: all 0.8s ease 0s;
  height: 30px;
  width: 30px;
  margin-top: 24px;
  margin-right: 80px;
  }

}
.social:hover {
  
  transition: all 0.8s ease 0s;
  > a > img {
    color: rgba(0,0,0,1);
    opacity: .9 !important;
    transition: all 0.3s ease 0s;
  }

}

@media only screen and (max-width: 600px) {
  .social {
    transition: all 0.8s ease 0s;
    
    > a > img {
      color: rgba(0,0,0,.2);
      opacity: .55 !important;
      transition: all 0.8s ease 0s;
      height: 30px;
      width: 30px;
      margin-top: 24px;
      margin-right: 10px;
    }

  }
  .social:hover {
    opacity: .9 !important;
    transition: all 0.8s ease 0s;
    > a {
    color: rgba(0,0,0,1);
    transition: all 0.3s ease 0s;
    }
  }
}
.tooltipa {
  display: none;
  position: absolute;
  top: 8px; 
  left: 10px;
  padding: 5px; 
  margin: 10px;
  z-index: 100;
  // background: #333;
  // border: 1px solid #c0c0c0;
  opacity: 0.8; 
  width: 300px;
  // color: black;
  text-align: left;
}

.singleImg {
  // object-fit: cover !important;
  
  max-height: 800px !important;
  max-width: 800px !important;
}


.imgback {
  // object-fit: cover !important;
  float: right;
  // position: absolute;
  text-decoration: none !important;
  color: black;
  border: 2px dotted #dfd9cf !important;
  margin-left: 10px !important;
  padding-left: 8px !important;
  padding-right: 8px !important;
  padding-bottom: 0px !important;
  padding-top: 6px !important;
  
  
}
.imgback:hover {
  // position: absolute;
  // text-decoration: none;
  color: black;
  border: 2px dotted #0a0a0a !important;
}

.galleryblock {
  float: center;
  text-align: center !important;
}
.gallerylink {
  float:center;
  vertical-align: middle !important;
  margin-top: 50ux;
  font-size: 28px;
  text-decoration: none !important;
  position: relative;
  background-color: rgba(254, 248, 237, 0.3) !important;
  // color: #111111 !important;
  color: rgba(43, 44, 44, .9) !important;
  opacity: 1.0;
  font-family: 'Roboto Condensed';
  font-weight: 800;
  border: 2px dotted rgba(136, 132, 123, 0.8);
  // margin: 10px 5px 5px 30px !important;
  transition: all 0.8s ease 0s;
  padding: 6px;
}
.gallerylink:active {
  color: #172427 !important;
  background-color: rgba(254, 248, 237, 0.6) !important;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 4px;
  transition: all 1s ease 0s;
  padding: 6px;
  //margin: 10px 5px 5px 30px !important;
}
.gallerylink:hover {
  //width: 20px;
  background-color: rgba(254, 248, 237, 0.8) !important;
  color: #424242 !important;
  border: 2px dotted rgba(44, 52, 54, 1);
  border-radius: 2px;
  // margin: 10px 5px 5px 30px !important;
  transition: all 0.3s ease 0s;
  //padding: 6px;
}

.gallerylink:focus {
  color: #172427 !important;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 2px;
  transition: all 0.5s ease 0s;
  padding: 6px;
}
.gallerylink:visited {
  position: relative;
  list-style-type: none;
  color: rgb(70, 75, 77);
  text-decoration: none;
  transition: all 0.6s ease 0s;
  margin: 30px 5px 5px 30px !important;
  padding: 8px;
}




.sansserif {
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}
.sanscondensed {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 300;
}

.pageformat {
  padding-top: 132px;
  padding-left: 50px;
  padding-bottom: 40px;
}
@media only screen and (max-width: 600px) {
  .pageformat {
    padding-top: 122px;
    padding-left: 40px;
    padding-bottom: 40px;
  }
}

.under {
  max-width: 800px !important;
}

.imgformat {
  padding-top: 112px;
  margin: 0 10%;
  padding-left: 50px;
  padding-bottom: 40px;
  width: 70%;
}
@media only screen and (max-width: 600px) {
  .imgformat {
    // padding-top: 122px;
    padding-left: 40px;
    padding-bottom: 40px;
  }
}

.homebody {
  position: absolute;
  overflow: hidden;
}

.homepageformat {
  overflow: hidden;
  position: absolute;

  -ms-flex-align: center;
  -ms-flex-pack: center;
  -webkit-box-align: center;
  align-items: center;
  padding-top: 229px;
  padding-left: 0px;
  padding-bottom: 40px;
}

@media only screen and (max-width: 600px) {
  .homepageformat {
    padding-left: 35px;
  }
}

.homeblock {
  > h1 {
    background-color: rgba(254, 248, 237, 0.6) !important;
    //color: rgb(43, 44, 44);
    padding: 20px;
    border: 2px dotted #d1ccc2;
    transition: all 1s ease 0s;
    max-width: 400px;
  }

  > h1:hover {
    padding: 20px;
    border: 2px dotted #2e262f;
    transition: all 0.5s ease 0s;
  }
}
.timetable {
  // z-index: -1 !important;
  //background-color: rgba(81, 103, 109, 0.1);
  > ul {
    // z-index: -1 !important;
    background-color: rgba(189, 185, 174, 0.1);
    border: 2px dotted #d1ccc2;
    transition: all 1s ease 0s;
  }
  > ul:hover {
    border: 2px dotted #5f5e5c;
    transition: all 0.5s ease 0s;
  }
}

.timedesc {
  z-index: 115 !important;
  > ul {
    background-color: rgba(189, 185, 174, 0.1);
    border: 2px dotted #d1ccc2;
    margin-top: 0px !important;
    margin-bottom: 0px;
    align-self: center;
    padding-top: 14px;

    padding-bottom: 0px;
    transition: all 1s ease 0s;
    line-height: 1.2 !important;

    > li {
      padding: 0px;
    }
  }
  > ul:hover {
    border: 2px dotted #5f5e5c;
    transition: all 0.5s ease 0s;
  }
}

//
//START TOOL TIP - pop ups for full description in timeline
.wrapper {
  -webkit-transform: translateZ(0); /* webkit flicker fix */
  -webkit-font-smoothing: antialiased; /* webkit text rendering fix */
}

//
//TOOL TIPS FOR LARGE SCREENS
//
@media only screen and (min-width: 600px) {
  .timedesc .tooltip {
    z-index: 11510 !important;
    background-color: rgba(254, 248, 237, 0.94);
    bottom: 100%;
    font-size: var(--font-title1-size) !important;
    display: block;
    right: 0px;
    margin-bottom: -60px;
    opacity: 0;
    padding: 20px;
    //pointer-events: none;
    position: absolute;
    width: 60%;
    border: 2px dotted #5f5e5c;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    -webkit-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.28);
  }

  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  .timedesc .tooltip:before {
    bottom: -20px;
    content: ' ';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 50%;
    flex-direction: column !important;
    flex-basis: auto;
  }

  /* CSS Triangles - see Trevor's post */
  .timedesc .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #1496bb 10px;
    bottom: -10px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
    // z-index: 99 !important;
    flex-direction: column !important;
    flex-basis: auto;
  }

  .timedesc:hover .tooltip {

    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -ms-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    flex-direction: column;
    flex-basis: auto;
  }
}
//TOOL TIPS FOR IPHONE SCREENS
@media only screen and (max-width: 600px) {
  .timedesc .tooltip {
    z-index: 1151 !important;
    background-color: rgba(254, 248, 237, 0.95);
    bottom: 100%;
    font-size: var(--font-title1-size) !important;
    display: block;
    left: 0px;
    margin-bottom: 40px;
    opacity: 0;
    padding: 25px;
    //pointer-events: none;
    position: absolute;
    width: 110%;
    border: 2px dotted #5f5e5c;
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    transform: translateY(10px);
    -webkit-transition: all 0.25s ease-out;
    -moz-transition: all 0.25s ease-out;
    -ms-transition: all 0.25s ease-out;
    -o-transition: all 0.25s ease-out;
    transition: all 0.25s ease-out;
    -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -ms-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    -o-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
    box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.28);
  }

  /* This bridges the gap so you can mouse into the tooltip without it disappearing */
  .timedesc .tooltip:before {

    bottom: -50px;
    content: ' ';
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 50%;
  }

  /* CSS Triangles - see Trevor's post */
  .timedesc .tooltip:after {
    border-left: solid transparent 10px;
    border-right: solid transparent 10px;
    border-top: solid #1496bb 10px;
    bottom: -10px;
    content: ' ';
    height: 0;
    left: 50%;
    margin-left: -13px;
    position: absolute;
    width: 0;
  }

  .timedesc:hover .tooltip {

    opacity: 1;
    pointer-events: auto;
    -webkit-transform: translateY(0px);
    -moz-transform: translateY(0px);
    -o-transform: translateY(0px);
    transform: translateY(0px);
    transition: all 0.25s ease-out;
  }
}

/* IE can just show/hide with no transition */
.lte8 .timedesc .tooltip {
  display: none;
}

.lte8 .timedesc:hover .tooltip {
  display: block;
}

.hoverbox {
  //display: block;
  //position: absolute;
  //-ms-flex-align: stretch;
  //align-items: stretch;
  //-ms-flex-pack: justify;
  //text-align: center;
  //overflow-x: auto;
  //vertical-align: bottom;
  padding: 20px;
  background-color: rgba(254, 248, 237, 0.95);
  border: 2px dotted #5f5e5c;
  //max-width: 500px;
  max-width: calc(80% + 10px);
  max-height: calc(60%-10px);
  bottom: 100%;
  //align-self: center;
  //margin-bottom: 100px;
  //left: 10px;
  transition: all 1.5s ease 0s;
}

.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.tab {
  margin-left: 40px;
}

.header {
  font-family: 'Roboto Condensed', sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 20px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(160, 15, 15);
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.pagemargins {
  margin-top: 170px !important;
  margin-left: 40px !important;
}

.navbar-collapse {
  position: absolute;
  top: 4px;
  right: 0px;
}

.nav-linx {
  //display: block;
  isolation: isolate;

  //padding: 0.5rem 1rem;
  //text-decoration: none;
  //transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
  //border-color 0.15s ease-in-out;
}
/*
.navbar {
  //height: 0px;
  overflow: visible;
  //position: relative;
  //margin-top: -20px;
  //margin-left: 10px;
  border: none;
  //z-index: 100;
  transition: background-color 1s ease 0s;
}*/
/*
.h1 {
  font-family: "Roboto Condensed";
  font-size: 28px !important;
  color:rgba(0, 0, 0, 0.88);
  font-weight: 700;
  //margin-top: 10px;
  //margin-left: 0px;
  //overflow: visible;
}*/
/*
.navbar-collapse {
  color:rgb(0, 0, 0);

  width:30%; 
}
.nav-item {
  color:rgb(115, 124, 126);
  text-decoration: none !important;
}*/

//.navbar-nav a:hover{
// color:rgb(0, 21, 34)  !important;
//}

.icon-bar {
  width: 150%;
  //position: relative;
  //height: 30px;
  background-color: transparent;
  //overflow:visible;
  //margin-top: -18px;
  //margin-left: 900px;
  z-index: 99;
  text-decoration: none;
  //margin-right: 1rem;
}

.icon-bar a {
  text-decoration: none;
  //position: relative;
  //float: left;
  //width: 15%;
  height: 20%;
  //text-align: center;
  //padding: 15px 0;
  transition: all 0.3s ease;
  color: #2d3131;
  //font-size: 26px;
}

.icon-bar a:hover {
  //background-color: rgb(166, 210, 218);
  //background-blend-mode: multiply;
  color: rgb(0, 29, 41);
  //opacity: .7;
  //font-weight: 400;
}

.post {
  position: absolute;
  margin-top: 104px;
  margin-left: 100px;
}

.about {
  width: 50%;
  align-items: center !important;
}

@media only screen and (max-width: 600px) {
  .about {
    width: 100%;
    align-items: center !important;
  }
}


.timeline {
  width: 60%;
  align-items: center !important;
  float: center !important;
}

@media only screen and (max-width: 600px) {
  .timeline {
    width: 100%;
    align-items: center !important;
    float: center !important;
  }
}

.timecalc {
  z-index: 0 !important;
}
.history {
  // z-index: 100 !important;
}

//Navbar left and right

.navbar-right {
  float: right;
}

//Navbar Logo formatting
.navbar-logo {
  > li {
    list-style-type: none;

    margin-left: 20px !important;

    > a {
      text-decoration: none;
      font-family: 'Roboto Condensed';
      font-size: 30px !important;
      font-weight: 800;
      background-color: rgba(254, 248, 237, 0.6) !important;
      color: rgb(43, 44, 44);
      border: 2px dotted #fef8ed;
      transition: all 0.1s ease 0s;
      //margin: 40px 15px 15px 5px;
      //margin-left: 5px;
      padding-left: 9px;
      padding-right: 9px;
      padding-bottom: 5px;
      padding-top: 5px;
      transition: all 0.5s ease 0s;
    }
    > a:hover {
      color: #a3a3a3 !important;
      border: 2px dotted #2e262f;

      transition: all 0.2s ease 0s;
    }
    > a:active,
    > a:focus {
      transition: all 0.4s ease 0s;
    }
    > a:visited {
      color: rgb(32, 32, 32);
      text-decoration: none;
      transition: all 0.4s ease 0s;
    }
  } //end li
} //end .navbar-logo

@media only screen and (max-width: 600px) {
  //Cell phone screen logo side of navbar
  .navbar-logo {
    > li {
      list-style-type: none;
      margin-left: 10px !important;
      > a {
        //@apply px-4 py-2 bg-blue-600 text-white rounded;
        text-decoration: none;
        font-family: 'Roboto Condensed';
        font-size: 26px !important;
        font-weight: 800;
        background-color: rgba(254, 248, 237, 0.6) !important;
        color: rgb(63, 63, 63) !important;
        border: 2px dotted #fef8ed;
        transition: all 0.1s ease 0s;
        //margin: 40px 15px 15px 5px;
        //margin-left: 50px !important;
        margin-top: 10px;
        margin-bottom: 10px;
        padding-left: 9px;
        padding-right: 9px;
        padding-bottom: 5px;
        padding-top: 5px;
        transition: all 0.5s ease 0s;
      }
      > a:hover {
        color: #222222 !important;
        border: 2px dotted #2e262f;

        transition: all 0.2s ease 0s;
      }
      > a:active,
      > a:focus {
        //color: #172427 !important;
        //border: 2px solid #2e262f;
        transition: all 0.4s ease 0s;

        //padding-left: 8px;
        //padding-right: 8px;
        //padding-top: 2px;
        //padding-bottom: 2px;
        //margin: 0px 0px 0px -3px;
      }
      > a:visited {
        color: rgb(58, 58, 58) !important;
        text-decoration: none;
        margin-top: 10px;
        margin-bottom: 10px;
        //margin-left: 50px !important;
        transition: all 0.4s ease 0s;
      }
    } //end li
  }
  //CELL PHONE navbar links
  .navbar-nav {
    //padding-left: (5px * .5) !important;
    //padding-right: (5px * .5) !important;
    //color:rgb(0, 209, 45) !important;
    //float: right;

    margin: 30px 5px 5px 30px !important;
    font-size: 18px !important;
    font-weight: 800;
    //width: 30%;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
    padding-left: 8px;
    padding-right: 8px;
    
    
    transition: all 0.4s ease 0s;

    > li {
      padding-top: 20px;
      float: right !important;

      > a {
        
        //float: right;
        text-decoration: none;
        position: relative;
        background-color: rgba(254, 248, 237, 0.6) !important;
        color: #464b4d;
        font-family: 'Roboto Condensed';
        font-weight: 800;
        border: 2px dotted #494947 !important;
        margin: 10px 5px 5px 30px !important;
        transition: all 0.8s ease 0s;
        padding: 6px;
      }
      > a:active {
        color: #172427 !important;
        background-color: rgba(254, 248, 237, 0.6) !important;
        border: 2px solid rgb(0, 0, 0);
        border-radius: 4px;
        transition: all 1s ease 0s;
        padding: 6px;
        //margin: 10px 5px 5px 30px !important;
      }
      > a:hover {
        //width: 20px;
        background-color: rgba(254, 248, 237, 0.8) !important;
        color: #424242 !important;
        border: 2px dotted rgba(81, 103, 109, 0.5);
        border-radius: 2px;
        margin: 10px 5px 5px 30px !important;
        transition: all 0.3s ease 0s;
        //padding: 6px;
      }

      > a:focus {
        color: #172427 !important;
        border: 2px solid rgb(0, 0, 0);
        border-radius: 2px;
        transition: all 0.5s ease 0s;
        padding: 6px;
      }
      > a:visited {
        position: relative;
        list-style-type: none;
        color: rgb(70, 75, 77);
        text-decoration: none;
        transition: all 0.6s ease 0s;
        margin: 30px 5px 5px 30px !important;
        padding: 8px;
      }
    } //end li
  } //end .navbar-nav
}

.active-nav {
  color: #172427;
  border: 2px solid rgb(0, 0, 0);
  border-radius: 2px;
  //transition: all .5s ease 0s;
  //padding: 6px;
}

.navbar-nav {
  //padding-left: (5px * .5) !important;
  //padding-right: (5px * .5) !important;
  //color:rgb(0, 209, 45) !important;
  //float: right;
  margin: 30px 5px 5px 30px !important;
  font-size: 20px;
  font-weight: 800;
  //width: 30%;
  padding-left: 8px;
  padding-right: 8px;
  transition: all 0.4s ease 0s;

  > li {
    > a {
      //float: right;
      text-decoration: none;
      position: relative;
      background-color: rgba(254, 248, 237, 0.4) !important;
      color: rgb(70, 75, 77);
      font-family: 'Roboto Condensed';
      font-weight: 800;
      border: 2px dotted #d1ccc2;
      margin: 10px 5px 5px 30px !important;
      transition: all 0.8s ease 0s;
      padding: 6px;
    }
    > a:active {
      color: #172427 !important;
      border: 2px solid rgb(0, 0, 0);
      border-radius: 4px;
      transition: all 1s ease 0s;
      padding: 6px;
      //margin: 10px 5px 5px 30px !important;
    }
    > a:hover {
      //width: 20px;
      color: #424242 !important;
      border: 2px dotted rgba(87, 105, 109, 0.8);
      border-radius: 2px;
      margin: 10px 5px 5px 30px !important;
      transition: all 0.3s ease 0s;
      //padding: 6px;
    }

    > a:focus {
      color: #172427 !important;
      border: 2px solid rgb(0, 0, 0);
      border-radius: 2px;
      transition: all 0.5s ease 0s;
      padding: 6px;
    }
    > a:visited {
      position: relative;
      list-style-type: none;
      color: rgb(70, 75, 77);
      text-decoration: none;
      transition: all 0.6s ease 0s;
      margin: 30px 5px 5px 30px !important;
      padding: 8px;
    }
  } //end li

  > .active {
    > a,
    > a:hover,
    > a:focus {
      background-color: transparent;
      color: #c57ed3;
      margin: 30px 5px 5px 30px !important;
    }
  }
} //end .navbar-nav

//begin header
//

.headerb {
  background: none;
  color: #fff;
  height: 0vh;
  position: relative;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  -msn-background-size: cover;
  margin-top: -20px;
  margin-left: 0px;
}
.headerb .overlay {
  background: rgba(0, 0, 0, 0.58);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 0px;
  margin-left: 0px;
}

.headerb .navbar-default {
  background-color: transparent !important;
  border-color: transparent;
  background-image: none;
  box-shadow: none;
}
/*.headerb .navbar-default .navbar-brand{
  font-size: 18px;
  font-family: "Roboto Condensed";
  font-weight: 300;
  color: black;
}*/
/*
.headerb .navbar-default .navbar-nav>a {
  font-family: "Roboto Condensed";
  font-size:40px; 
  font-weight: 300;
  color:#04ADBF; 
  border-bottom: 1px solid transparent;
  text-decoration: none;
}
.headerb .navbar-default .navbar-nav>li:hover a{
  color: #333;
  border-bottom: 1px solid #FFF
}


.headerb .navbar-toggle {
    margin:10px 0; 
    background:#36C; 
    color:#FFF;
}



//end Header
*/

/*
.image {
  background-color: #490D40;
  background: url(https://sonorangirl.github.io/Earth-Quotes/img/google-earth-view-South-Iceland3.jpg) no-repeat center center;
  background-size: cover;
  height: 570px;
  margin-top: -20px;
  
  .heading {
     color: white;
     text-align: center;
     padding-top: 200px;
  }

  p {
     text-align: center;
     padding: 20px 60px;
     
      .btn {
        background-color: transparent;
        color: white;
        border: 1px solid white;
        padding: 10px;
        font-size: 1.2em;
            
         &:hover {
            color: #C57ED3;
            border-color: #C57ED3;
         }
     }
  }
}

.navbar {
  height: 80px;
  background-color: transparent;
  border: none;
  color: white;
  z-index: 100;
  transition: background-color 1s ease 0s;
}

.navbar-default {
  
  .navbar-brand {
    margin-top: 10px;
    color: white;
       
     &:hover {
        color: #C57ED3;
        border: 1px solid #C57ED3;
     } 
  }
  
 .navbar-nav {
   
   > li {
     
     > a {
       color: white;
       margin: 10px 5px 5px 5px;
       
     }
     > a:hover, > a:active, > a:focus {
       color: #C57ED3;
       border: 1px solid #C57ED3;
     }
     > a:visited {
       color: #C57ED3;
       text-decoration: none;
     }

   } //end li

   > .active {

     > a, > a:hover, > a:focus {
       background-color: transparent;
       color: #C57ED3;
     }

   }

 } //end .navbar-nav

 //Collapsed styles
 .navbar-toggle {
   border-color: #C57ED3;

     &:hover, &:focus {
       background-color: #490D40;
     }



     .icon-bar {
       color: #C57ED3;
       background-color: #C57ED3;

     }
 }

 .navbar-collapse.collapse.in ul {
   background-color: #490D40;
 }
   
} */

.jam {
  overflow-y: hidden;
  overflow-x: hidden;
}
.pagez {
  z-index: 0;
  //background-color: #FEF8ED;

  //margin-left: 0px;

  //overflow-y:hidden !important;
  //overflow-x:hidden;
}
